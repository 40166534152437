'use client';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { I18nextProvider as Provider, initReactI18next } from 'react-i18next';
import { getOptions } from './settings';
import { useMemo } from 'react';
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(resourcesToBackend(function (language, ns) {
    return import("./locales/".concat(language, "/").concat(ns, ".json"));
}))
    .init(__assign(__assign({}, getOptions()), { detection: {
        caches: ['cookie'],
    } }));
export function I18nProvider(_a) {
    var children = _a.children, language = _a.language;
    useMemo(function () {
        i18next.changeLanguage(language);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return _jsx(Provider, { i18n: i18next, children: children });
}
